<template>
  <div class="sub-account-container">
    <Title name="账户管理"></Title>
    <div class="add-btn" @click="showAddSubDialog">+添加</div>
    <div style="padding:10px 10px;background:#fff">
      <el-table :header-cell-style="header_cell_style" :cell-style="cellStyle" :data="tableData" style="width: 100%">
        <el-table-column prop="loginName" label="登录名" width="180"> </el-table-column>
        <el-table-column prop="users" label="用户名" width="180"> </el-table-column>
        <el-table-column prop="tel" width="180" label="联系方式"> </el-table-column>
        <el-table-column prop="accountType" width="180" label="类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.accountType == 'SELL'">供应商</el-tag>
            <el-tag v-if="scope.row.accountType == 'BUY'" type="success">买家</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="" width="180" label="账号">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.parentId == undefined">主账号</el-tag>
            <el-tag v-if="scope.row.parentId != undefined" type="success">子账号</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="statusText" label="状态"> </el-table-column>
        <el-table-column label="操作" align="center" width="280">
          <template slot-scope="scope">
            <el-button size="small" round v-if="scope.row.parentId == undefined" @click="operat(scope.row, 'check')" :style="button_style">查看</el-button>
            <!-- <el-button size="small" round v-if="scope.row.parentId !== undefined" @click="deleteEditSubDialog(scope.row)" :style="button_style" >删除</el-button> -->
            <el-button size="small" round v-if="scope.row.parentId !== undefined" @click="operat(scope.row, 'revise')" :style="button_style"> 修改</el-button>
            <el-button size="small" round @click="operat(scope.row, 'ability')" :style="button_style">权限</el-button>
            <el-button size="small" round v-if="scope.row.parentId !== undefined" @click="switchAccountStatus(scope.row.loginName, scope.row.isDisable)" :style="button_style">{{
              scope.row.btnText
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :title="subTitle" :visible.sync="addSubdialogVisible" @close="closeAddSubdialog" width="500px">
      <div class="form-content">
        <el-form :model="addSubForm" :rules="addSubFormRules" ref="addSubForm" label-width="80px">
            <el-form-item label="登录名" prop="loginName" v-if="form_name !== 'ability' ">
              <el-input v-model="addSubForm.loginName" :disabled="form_name !== 'add'" @blur="loginNameBlur" placeholder="请输入登录名"> </el-input>
            </el-form-item>

          <div v-if="form_name == 'add'">
              <el-form-item label="密码" prop="loginPwd" >
                <el-input v-model="addSubForm.loginPwd" type="password" placeholder="请输入密码"></el-input>
              </el-form-item>

              <el-form-item label="确认密码" prop="againPassword">
                <el-input v-model="addSubForm.againPassword" auto-complete="new-password" type="password" placeholder="请输入确认密码"></el-input>
              </el-form-item>
          </div>
          <div v-if="form_name == 'add' || form_name == 'revise' || form_name == 'check'">
            <el-form-item label="用户名" prop="users">
              <el-input v-model="addSubForm.users" :disabled="form_name == 'check'" placeholder="请输入用户名"></el-input>
            </el-form-item>

            <el-form-item label="手机号" prop="tel">
              <el-input v-model="addSubForm.tel" :disabled="form_name == 'check'" @blur="phoneBlur" type="phone" :maxlength="11" placeholder="请输入手机号"></el-input>
            </el-form-item>

            <el-form-item v-if="form_name !== 'check'" label="验证码" prop="code">
              <el-input placeholder="请输入验证码" v-model="addSubForm.code" style="width:196px;margin-right:14px" />
              <el-button class="send_code" @click="getCode('addSubForm')">{{ getCodeShow ? '发送验证码' : count + 's' }} </el-button>
            </el-form-item>
          </div>

          <el-form-item class="form-item" label="类型：" prop="accountType">
            <el-select
              :disabled="form_name == 'ability' || form_name == 'check'"
              v-model="addSubForm.accountType"
              placeholder="请选择类型"
              style="width: 100%;"
            >
              <el-option v-for="item in accountTypes" :key="item.accountTypeNo" :label="item.accountTypeName" :value="item.accountTypeNo"> </el-option>
            </el-select>
          </el-form-item>

          <div v-show="form_name == 'ability'">
            <el-form-item class="form-item" label="角色：">
              <el-checkbox-group v-model="addSubForm.roleIds" @change="roleId" style="width: auto" size="small">
                <el-checkbox style="margin-left: -9px" v-for="(item, key) in roles" :key="key" :label="parseInt(key)" :disabled="item.name == '管理员' && isUser == true" border>{{ item.name }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <el-form-item class="form-item" label="权限：">
              <el-tree
                ref="el_tree"
                :data="treeData"
                show-checkbox
                node-key="id"
                :default-checked-keys="default_key"
                :default-expanded-keys="default_expanded"
                v-model="addSubForm.permissions"
                :props="defaultProps"
                @check='checkTree'
              >
              </el-tree>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span v-show="form_name !== 'check'" slot="footer" class="dialog-footer">
        <el-button @click="addSubdialogVisible = false">取 消</el-button>
        <el-button v-if="form_name == 'add' || form_name == 'revise'" type="primary" @click="addSubAccount">确 定</el-button>
        <el-button v-if="form_name == 'ability'" type="primary" @click="addSubPermiss">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Title from '../component/Title';
import {getPermission} from '@/modules/Login/api.js';
import {
  isAuthority,
  verifyLoginName,
  selectCountByTel,
  registerBypass,
  selectSubInfo,
  disableByLoginName,
  updateSubInfoByLoginName,
  deleteById,
  getCode,
  updatePermission,
  permissionByAllRole,
  permissionByParent
} from '../api';
import {regRule} from '@/utils/validate';
export default {
  created: function() {
    this.getSubInfo();
    this.getPermissionByRole();
    this.getPermissionByParent();
  },
  components: {
    Title
  },
  computed: {
    accountTypes() {
      if (!this.tableData[0]) return [];
      if (this.tableData[0].accountType == 'SELL') {
        return [
          {accountTypeNo: 'SELL', accountTypeName: '供应商'},
          {accountTypeNo: 'BUY', accountTypeName: '买家'}
        ];
      } else {
        return [{accountTypeNo: 'BUY', accountTypeName: '买家'}];
      }
    },
    treeData(){
      if (this.addSubForm.accountType == 'SELL') {
        return this.all_data.sell
      } else {
        return this.all_data.buy;
      }
    }
  },
  data() {
    var validatenickname = (rule, value, callback) => {
      var ok = true;
      var str = '';
      const FloatRegex = /^[0-9A-Za-z]+$/; // 不能出现特殊字符
      const FloatRegex2 = /^.{6,8}$/; //6-8
      const FloatRegex3 = /^[^\u4e00-\u9fa5]+$/; //不能中文
      const FloatRegex4 = /[a-zA-Z]+/; //必须包含字母
      const FloatRegex5 = /[0-9]+/; //必须包含字母

      if (!FloatRegex.test(value)) {
        ok = false;
        callback(new Error('不能出现特殊字符'));
      }
      if (!FloatRegex2.test(value)) {
        ok = false;
        callback(new Error('长度6-8'));
      }

      if (!FloatRegex3.test(value)) {
        ok = false;
        callback(new Error('不能出现中文'));
      }
      if (!FloatRegex4.test(value)) {
        ok = false;
        callback(new Error('必须包含字母'));
      }
      if (!FloatRegex5.test(value)) {
        ok = false;
        callback(new Error('必须包含数字'));
      }

      if (ok) {
        callback();
      }
    };
    var validatepassword1 = (rule, value, callback) => {
      var ok = true;
      var pwd = true;
      const FloatRegex = /^[A-Za-z0-9~@#$*]*$/; //只能包含（~@#$*）
      const FloatRegex2 = /^.{6,8}$/; //6-8
      const FloatRegex3 = /^[^\u4e00-\u9fa5]+$/; //不能中文
      const FloatRegex4 = /[A-Z]+/; //必须包含大写字母
      const FloatRegex5 = /[a-z]+/; //必须包含小写字母
      const FloatRegex6 = /[0-9]+/; //必须包含小写字母

      if (!FloatRegex.test(value)) {
        ok = false;
        callback(new Error('不能出现特殊字符,只能包含（~@#$*）'));
      }
      if (!FloatRegex2.test(value)) {
        ok = false;
        callback(new Error('长度6-8'));
      }

      if (!FloatRegex3.test(value)) {
        ok = false;
        callback(new Error('不能出现中文'));
      }
      if (!FloatRegex4.test(value)) {
        ok = false;
        pwd = false;
      }
      if (!FloatRegex5.test(value)) {
        ok = false;
        pwd = false;
        // callback(new Error("必须包含小写字母"))
      }
      if (!FloatRegex6.test(value)) {
        ok = false;
        pwd = false;
        // callback(new Error("必须包含数字"))
      }
      if (!pwd) {
        callback(new Error('必须包含大、小写字母和数字'));
      }

      if (ok) {
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if ('' !== this.addSubForm.againPassword) {
          if (value !== this.addSubForm.againPassword && this.addSubForm.againPassword !== '') {
            callback(new Error('两次输入密码不一致!'));
          } else {
            this.$refs.addSubForm.validateField('againPassword');
          }
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.addSubForm.loginPwd && this.addSubForm.loginPwd !== '') {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      isUser: false, // 是否为主账号
      form_name: '',
      user: '',
      subTitle: '添加子账户',
      tableData: [],
      addSubdialogVisible: false,
      addSubForm: {
        loginName: '',
        loginPwd: '',
        againPassword: '',
        users: '',
        tel: '',
        code: '',
        accountType: '',
        permissions: [],
        roleIds: []
      },
      addSubFormRules: {
        loginName: [
          {
            required: true,
            message: '请输入登录名'
          },
          {
            required: false,
            validator: validatenickname,
            trigger: 'blur'
          }
        ],
        loginPwd: [
          {
            required: true,
            validator: validatePass1,
            trigger: 'blur'
          },
          {
            required: false,
            validator: validatepassword1,
            trigger: 'blur'
          }
        ],
        againPassword: [
          {
            required: true,
            validator: validatePass2,
            trigger: 'blur'
          },
          {
            required: false,
            validator: validatepassword1,
            trigger: 'blur'
          }
        ],
        users: {
          required: true,
          message: '请输入用户名'
        },
        tel: {
          required: true,
          pattern: regRule.regPhoneStrict,
          message: '请输入正确的手机号码'
        },
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ],
        accountType: [
          {
            required: true,
            message: '请选择类型',
            trigger: ['blur', 'change']
          }
        ]
      },
      permiss: [],
      permisssell: [],
      permissbuy: [],
      roles: {},
      getCodeShow: true,
      count: 60,
      timer: null,
      data: [],
      all_data: {
        sell: [],
        buy: []
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      last_time_roleIds: [
        // 记录上一次已选中的角色，用于比较
      ],
      default_key: [], // 默认选中
      default_expanded: [], // 默认展开
      header_cell_style:{background: '#f5f5f5', 'font-weight': 500, color: '#666', padding: '12 5px'},
      button_style:{padding:'6px 10px','font-size':'12px'} 
    };
  },
  methods: {
    // 禁用 启用
    switchAccountStatus(name, isDisable) {
      disableByLoginName({
        loginName: name
      }).then(res => {
        if (res.errno == 0) {
          this.$message.success(isDisable ? '启用成功！' : '禁用成功！');
          this.getSubInfo();
        }
      });
    },

    //查询账号信息
    getSubInfo() {
      selectSubInfo().then(res => {
        if (res.errno == 0) {
          console.log(res, 'response');
          res.data.forEach(item => {
            item.btnText = item.isDisable ? '启用' : '禁用';
            item.statusText = item.isDisable ? '已禁用' : '已启用';
          });

          this.tableData = res.data;
        }
      });
    },

    // 确定权限
    addSubPermiss() {
      let arr = this.$refs.el_tree.getCheckedKeys();
      let data = [];
      arr.forEach(e => {
        if (e > 12) {
          data.push(e);
        }
      });
      this.addSubForm.permissions = data;
      // .concat(this.$refs.el_tree.getHalfCheckedKeys())
      // console.log(this.addSubForm)
      updatePermission({
        ...this.addSubForm
      }).then(res => {
        if (res.errno === 0) {
          this.$message({
            type: 'success',
            message: '修改成功！'
          });
          this.getSubInfo();
          this.addSubdialogVisible = false;
          // 获取当前账号的所有权限
          getPermission();
        }
      });
    },

    // 确定添加子账号或者修改子账号
    addSubAccount() {
      this.$refs.addSubForm.validate(valid => {
        // console.log("valid", valid)
        if (valid) {
          if (this.form_name == 'add') {
            if (this.addSubForm.loginPwd !== this.addSubForm.againPassword) {
              return this.$message.warning('密码与确认密码不一致！');
            }
          }

          this.getCodeShow = true;
          clearInterval(this.timer);
          this.timer = null;

          if (this.form_name == 'add') {
            registerBypass({
              ...this.addSubForm,
              enterpriseId: '0'
            }).then(res => {
              if (res.errno == -1) {
                // this.$message.warning("已经有五个子账号")
              } else if (res.errno == 704) {
                // this.$message.warning("登录名已经使用")
              } else if (res.errno == 705) {
                // this.$message.warning("手机已经使用")
              } else if (res.errno == 0) {
                this.$message.warning('成功');
                this.getSubInfo();
                this.addSubdialogVisible = false;
              } else {
                // this.$message.warning("其他异常")
              }
            });
          } else {
            let addSubForm = {...this.addSubForm};
            if (typeof addSubForm.roleIds == 'string') {
              addSubForm.roleIds = JSON.parse(addSubForm.roleIds);
            }
            console.log(addSubForm.roleIds, addSubForm.roleIds.length);
            let buy_index = addSubForm.roleIds.indexOf(22);
            let sell_index = addSubForm.roleIds.indexOf(23);
            console.log(buy_index, 'buy_index');
            console.log(sell_index, 'sell_index');
            if (addSubForm.accountType == 'BUY') {
              if (sell_index !== -1) {
                addSubForm.roleIds.splice(sell_index, 1);
              }
              if (buy_index == -1) {
                addSubForm.roleIds.push(22);
              }
            } else {
              if (buy_index !== -1) {
                addSubForm.roleIds.splice(buy_index, 1);
              }
              if (sell_index == -1) {
                addSubForm.roleIds.push(23);
              }
            }
            updateSubInfoByLoginName(addSubForm).then(res => {
              if (res.errno === 0) {
                this.$message({
                  type: 'success',
                  message: '修改成功！'
                });
                this.getSubInfo();
                this.addSubdialogVisible = false;
              }
            });
          }
        }
      });
    },

    // 删除
    deleteEditSubDialog(data) {
      this.$confirm('此操作将永久删除该账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteById({
          id: data.id
        }).then(res => {
          if (res.errno == 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getSubInfo();
          } else if (res.errno == -1) {
            // this.$message({
            //   type: 'warning',
            //   message: '删除失败!'
            // });
          } else if (res.errno == -503) {
            // this.$message({
            //   type: 'warning',
            //   message: '业务不支持!'
            // });
          }
        });
      });
    },

    // 编辑权限
    showEditPermission(data) {
      //编辑子账户权限
      this.subTitle = '编辑账户权限';
      
      if (data.parentId == undefined) {
        this.isUser = true;
      } else {
        this.isUser = false;
      }
      Object.keys(this.addSubForm).forEach(key => {
          this.addSubForm[key] = data[key];
      });

      // console.log(data,'data')
      // console.log(data.roleIds,'roleIds')
      // console.log(data.permissions,'permissions')
      this.default_key = [];
      this.default_expanded = [];
      this.last_time_roleIds = JSON.parse(data.roleIds);
      this.addSubForm.roleIds = JSON.parse(data.roleIds);
      console.log(this.addSubForm.roleIds,'this.addSubForm.roleIds')
      // 将已经勾选的角色对应的权限勾选上并且禁用
      JSON.parse(data.roleIds).forEach(res => {
        this.initializeRoled(res);
      });
      data.permissions = data.permissions.map(e=>{
        return parseInt(e)
      })
      this.default_key = data.permissions.concat(this.default_key);
      this.default_key = this.unique(this.default_key);
      this.addSubdialogVisible = true;
      // this.addSubFormRules.loginName.required = false;
      // this.addSubFormRules.users.required = false;
    },

    // 修改
    showEditSubDialog(data) {
      //修改子账户
      this.subTitle = '修改子账户信息';
      // this.addSubFormRules.loginName.required = false;
      // this.addSubFormRules.users.required = false;

      Object.keys(this.addSubForm).forEach(key => {
        this.addSubForm[key] = data[key];
      });
      this.$nextTick(e=>{
        this.addSubdialogVisible = true;
      })
      
    },

    // 查看
    showSubDialog(data) {
        //查看主账户信息
        // // console.log(data)
      this.subTitle = '查看主账户信息';
      this.addSubdialogVisible = true;
      // this.addSubFormRules.loginName.required = false;
      // this.addSubFormRules.users.required = false;

      Object.keys(this.addSubForm).forEach(key => {
        this.addSubForm[key] = data[key];
      });
    },

    // 操作
    operat(data, type) {
      switch (type) {
        case 'check':
          this.form_name = 'check';
          this.showSubDialog(data);
          break;
        case 'revise':
          this.form_name = 'revise';
          this.showEditSubDialog(data);
          break;
        case 'ability':
          this.form_name = 'ability';
          this.showEditPermission(data);
          break;
        default:
      }
    },

    // 添加子账号信息
    showAddSubDialog() {
      this.addSubForm = {
        loginName: '',
        loginPwd: '',
        againPassword: '',
        users: '',
        tel: '',
        code: '',
        accountType: 'BUY',
        permissions: [],
        roleIds: []
      },
      this.form_name = 'add';
      this.subTitle = '添加子账户信息';

      //点击添加子账户
      isAuthority().then(res => {
        if (res.errno == 1) {
          // this.$message.warning('当前账号为子账号，不能添加子账号');
        } else if (res.errno == 501) {
          // this.$message.warning("没有登录，请登录");
          // this.$router.push({
          //   path: '/home/Login'
          // });
        } else if (res.errno == 2) {
          // this.$message.warning('已经有五个子账号了不能在添加了');
        } else if (res.errno == -1) {
          // this.$message.warning('用户不存在');
          // this.$router.push({
          //   path: '/home/Login'
          // });
        } else {
          this.addSubdialogVisible = true;
        }
      });
    },

    // 关闭窗口-重置表单
    closeAddSubdialog() {
      this.$refs.addSubForm.resetFields();
    },

    // 校验登录名是否被使用
    loginNameBlur() {
      verifyLoginName({
        loginName: this.addSubForm.loginName
      }).then(res => {
        // if (res.errno == 704 ){
        //   this.$message.warning("登录名已经使用");
        // }
      });
    },

    // 校验手机号码
    phoneBlur() {
      if (this.form_name == 'revise') {
        selectCountByTel({
          tel: this.addSubForm.tel,
          scene: '1',
          loginName: this.addSubForm.loginName
        }).then(res => {
          // if (res.errno == 705) {
          //   this.$message.warning("手机号码已被使用");
          // }
        });
      } else {
        selectCountByTel({
          tel: this.addSubForm.tel
        }).then(res => {
          // if (res.errno == 705) {
          //   this.$message.warning("手机号码已被使用");
          // }
        });
      }
    },

    // 禁用状态样式
    cellStyle({row, column, rowIndex, columnIndex}) {
      if (columnIndex == 5) {
        if (row.statusText == '已禁用') {
          return {color: "#f56c6c"}
        }
      }
    },

    // 获取验证码
    getCode(formName) {
      const _this = this;
      if (this.getCodeShow === true) {
        this.$refs[formName].validateField('tel', valid => {
          if (!valid) {
            const TIME_COUNT = 60;
            if (!_this.timer) {
              _this.count = TIME_COUNT;
              _this.getCodeShow = false;
              _this.timer = setInterval(() => {
                if (_this.count > 0 && _this.count <= TIME_COUNT) {
                  _this.count--;
                } else {
                  _this.getCodeShow = true;
                  clearInterval(_this.timer);
                  _this.timer = null;
                }
              }, 1000);
            }

            getCode({
              phoneNum: _this.addSubForm.tel,
              scene: '4'
            }).then(response => {
              _this.addSubForm.uuid = response.data.uuid;
              if (response.data.ssm == '9') {
                this.$message.error('今日获取验证码数量已到达上限');
                return false;
              }
            });
          }
        });
      }
    },

    // 获取角色
    getPermissionByRole() {
      permissionByAllRole().then(e => {
        // console.log(e,'测试获取的角色')
        let contrast = {
          19: '经办',
          20: '审核',
          21: '设备合同管理员',
          // 22:'买家',
          // 23:'供应商',
          25: '管理员'
        };
        let data = {};
        if (e.errno == 0) {
          for (let key in e.data) {
            let name = contrast[key];
            if (name) {
              data[key] = {name};
              data[key].id = e.data[key].map(res => {
                return res.id;
              });
            }
          }
          this.roles = data;
        }
      });
    },

    // 获取所有的权限
    getPermissionByParent() {
      permissionByParent().then(e => {
        // console.log(e,'所有权限')
        if (e.errno == 0) {
          // 格式化数据
          function formatData(arr) {
            let level_one = {};
            let res = [];
            arr.forEach(e => {
              for (let key in e) {
                if (!level_one[key]) {
                  level_one[key] = {
                    label: key,
                    id: e[key].fid
                  };
                  level_one[key].children = [];
                  let value = {
                    id: e[key].id,
                    label: e[key].permissionName
                  };
                  level_one[key].children.push(value);
                } else {
                  let value = {
                    id: e[key].id,
                    label: e[key].permissionName
                  };
                  level_one[key].children.push(value);
                }
              }
            });

            for (let key in level_one) {
              res.push(level_one[key]);
            }
            return res;
          }
          if (e.data.SELL) {
            this.all_data.sell = formatData(e.data.SELL);
          }
          if (e.data.BUY) {
            this.all_data.buy = formatData(e.data.BUY);
          }
        }
      });
    },

    // 选择角色
    roleId(e) {
      // 当前是买家还是供应商
      let user = this.addSubForm.accountType == 'BUY' ? 'buy' : 'sell';
      if (this.last_time_roleIds.length > e.length) {
        // 取消
        // 找出取消的角色id
        let distinctive = '';
        this.last_time_roleIds.forEach(res => {
          if (e.indexOf(res) == '-1') {
            distinctive = res;
          }
        });
        let purviewIds = this.roles[distinctive].id;
        // console.log(purviewIds,'取消')
        purviewIds = this.transformMap(purviewIds);

        this.selected(purviewIds, 'cancel');
        this.disabled(purviewIds, user, 'cancel');
        this.last_time_roleIds = e;
        // console.log('取消角色的id:',distinctive)
      } else {
        // 选中
        let index = e[e.length - 1];
        this.last_time_roleIds = e;
        let purviewIds = this.roles[index].id;
        purviewIds = this.transformMap(purviewIds);
        // console.log(purviewIds,'选中')
        this.selected(purviewIds, 'select');
        this.disabled(purviewIds, user, 'select');
        // console.log('勾选角色的id:',distinctive)
      }
    },

    // 根据角色勾选
    initializeRoled(index) {
      let user = this.addSubForm.accountType == 'BUY' ? 'buy' : 'sell';
      if (this.roles[index]) {
        let purviewIds = this.roles[index].id;
        purviewIds = this.transformMap(purviewIds);
        // console.log(purviewIds,'purviewIds')
        // console.log(purviewIds,'选中')
        this.selected(purviewIds, 'select');
        this.disabled(purviewIds, user, 'select');
      }
    },

    // 用户选中和取消权限
    checkTree(e,value){
      // console.log(e,value)
      console.log(this.default_key)
      let id = parseInt(e.id);
      let index = this.default_key.indexOf(id);
      if(index == -1){
        this.default_key.push(id)
      } else {
        this.default_key.splice(index,1)
      }
    },

    /*
     * 勾选或者取消已勾选的角色时将其对应的权限禁用或者放开禁用
     * @param  {Object,string,string}  e：当前操作角色的所拥有的权限id user:买家还是供应商  type：勾选操作还是取消操作
    */
    disabled(e, user, type) {
      this.all_data[user] = this.all_data[user].map(res => {
        res.children = res.children.map(value => {
          if (e[value.id]) {
            if (type == 'select') {
              value.disabled = true;
            } else if (type == 'cancel') {
              value.disabled = false;
            }
          }
          return value;
        });
        return res;
      });
    },

    /*
     * 勾选或者取消已勾选的角色时将其对应的权限勾选上或者取消掉
     * @param  {Object,string}  e：当前操作角色的所拥有的权限id   type：勾选操作还是取消操作
    */
    selected(e, type) {
      for (let key in e) {
        key = parseInt(key)
        let index = this.default_key.indexOf(key);
        if (type == 'select') {
          if(index == -1){
            this.default_key.push(key);
          }
        } else if (type == 'cancel') {
          if (index !== -1) {
            this.default_key.splice(index, 1);
          }
        }
      }

      this.$nextTick(e => {
        this.default_expanded = this.$refs.el_tree.getCheckedKeys();
      });
    },

    // 数组去重
    unique(arr) {
      return Array.from(new Set(arr));
    },

    // 数组转对象，用于方便判断
    transformMap(arr) {
      let map = {};
      arr.forEach(item => {
        map[item] = item;
      });
      return map;
    }
  }
};
</script>

<style lang="less">
.sub-account-container {
  position: relative;
  background: #fff;

  .add-btn {
    position: absolute;
    top: 14px;
    right: 30px;
    cursor: pointer;
    color: #666;

    &:hover {
      color: #409eff;
    }
  }

  .form-content {
    margin: 0 auto;
    width: 400px;
  }

  .send_code {
    width: 110px;
    border: 1px solid #dcdfe6;

    &:hover {
      border: 1px solid #fff;
    }
  }
}
</style>
